
import { Trans } from 'i18n'
import styled, { useTheme } from 'styled-components'
import {
  CV_HOME_PAGE,
  CV_HEADER_LAYOUT, CV_HOME_HEADER, CV_LOGO, CV_MENU, CV_MENU_LI, CV_BTN_TYPE1,
  CV_HOME_BODY, CIRCLE_TOP, CIRCLE_BG_LEVEL, CIRCLE_CONTENT_LEVEL, CIRCLE_BOTTOM, CIRCLE_BTN_BG_LEVEL, CIRCLE_BTN_CONTENT_LEVEL, CV_ROW, CV_TITLE_IMG, CV_BLOCKS, CV_TEXT1, CV_FONT_WHITE, CV_START_BTN, CV_START_BTN_TEXT, CV_COLUMN, CV_TEXT2, CV_SON_TITLE, CV_IMG_LINE,
  CV_POOL_PANEL, CV_POOL_CONTENT, CV_POOL_TITLE_IMG, CV_POOL_IMG,
  CV_SWAP_PANEL, CV_SWAP_IMG, CV_SWAP_CONTENT, CV_SWAP_TITLE_IMG,
  CV_EXTRA_PANEL, CV_EXTRA_IMG, CV_EXTRA_TITLE_IMG, CV_FONT_YELLOW,
  CV_DIAN_LAYOUT, CV_EXTRA_BOX, CV_DIAN,
  CV_HOME_FOOTER_LAYOUT, CV_HOME_FOOTER, CV_ICONS, CV_ICON_IMG, CV_TEXT3   } from './styled'
import logoImg from '../../assets/images/home/logo.png'
import circleTopBg from '../../assets/images/home/circle1.png'
import titleImg from '../../assets/images/home/title.png'
import circleBottomBg from '../../assets/images/home/circle2.png'
import lineImg from '../../assets/images/home/line.png'
import poolTitleImg from '../../assets/images/home/v3 pool.png'
// import poolChartsImg from '../../assets/images/home/echarts.png'
import poolChartsImg from '../../assets/images/home/echarts.gif'

import swapTitleImg from '../../assets/images/home/swap.png'
import demoFormImg from '../../assets/images/home/demo-form.png'
import extraImg from '../../assets/images/home/extra.png'
import extraTitleImg from '../../assets/images/home/extra yields.png'
import icon1Img from '../../assets/images/home/icon-twitter.png'
import icon2Img from '../../assets/images/home/icon-discord.png'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const navigate = useNavigate()
  const toNextPage = (key: any) => {
    navigate(`/${key}`)
  }
  return <CV_HOME_PAGE>
      <CV_HEADER_LAYOUT>
        <CV_HOME_HEADER>
          <CV_LOGO src={logoImg} alt="logo" />
          <CV_MENU>
            <CV_MENU_LI onClick={() => toNextPage('swap')}><Trans>SWAP</Trans></CV_MENU_LI>
            <CV_MENU_LI onClick={() => toNextPage('pool')}><Trans>POOLS</Trans></CV_MENU_LI>
            {/* <CV_MENU_LI><Trans>Liquidity</Trans></CV_MENU_LI> */}
          </CV_MENU>
          {/* <CV_BTN_TYPE1><Trans>Connect Wallet</Trans></CV_BTN_TYPE1> */}
        </CV_HOME_HEADER>
      </CV_HEADER_LAYOUT>
      <CV_HOME_BODY>
        <CIRCLE_TOP>
          <CIRCLE_BG_LEVEL src={circleTopBg} alt="circleTop"></CIRCLE_BG_LEVEL>
          <CIRCLE_CONTENT_LEVEL>
            <CV_ROW>
              <CV_TITLE_IMG src={titleImg} title="titleImg"></CV_TITLE_IMG>
            </CV_ROW>
            <CV_ROW>
              <CV_BLOCKS></CV_BLOCKS>
                <CV_TEXT1><Trans>Your Gateway to DeFi on <CV_FONT_WHITE>TABI</CV_FONT_WHITE></Trans></CV_TEXT1>
              <CV_BLOCKS></CV_BLOCKS>
            </CV_ROW>
            <CV_ROW>
              <CV_START_BTN onClick={() => toNextPage('swap')}>
                <CV_START_BTN_TEXT>
                  <Trans>Get Started</Trans>
                </CV_START_BTN_TEXT>
              </CV_START_BTN>
            </CV_ROW>
          </CIRCLE_CONTENT_LEVEL>
        </CIRCLE_TOP>
        <CIRCLE_BOTTOM>
          <CIRCLE_BTN_BG_LEVEL src={circleBottomBg} alt="circleBottom"></CIRCLE_BTN_BG_LEVEL>
          {/* <CIRCLE_BTN_CONTENT_LEVEL>
            <CV_COLUMN>
              <CV_SON_TITLE><Trans>TVL</Trans></CV_SON_TITLE>
              <CV_TEXT2>$1,018,404,140.16</CV_TEXT2>
            </CV_COLUMN>
            <CV_COLUMN>
              <CV_IMG_LINE src={lineImg} alt="lineImg"></CV_IMG_LINE>
            </CV_COLUMN>
            <CV_COLUMN>
              <CV_SON_TITLE><Trans>24H Volume</Trans></CV_SON_TITLE>
              <CV_TEXT2>$1,403,809,350.729</CV_TEXT2>
            </CV_COLUMN>
          </CIRCLE_BTN_CONTENT_LEVEL> */}
          <CV_POOL_PANEL className='noshow'>
            <CV_POOL_CONTENT>
              <CV_POOL_TITLE_IMG src={poolTitleImg} alt="poolTitleImg"></CV_POOL_TITLE_IMG>
              <CV_ROW>
                <CV_BLOCKS></CV_BLOCKS>
                <CV_TEXT1 className='leftAlign'><Trans>Concentrated Liquidity Leads to <br/><CV_FONT_WHITE>Higher Earnings</CV_FONT_WHITE></Trans></CV_TEXT1>
              </CV_ROW>
              <CV_ROW className='leftAlign'>
                <CV_START_BTN onClick={() => toNextPage('pool')}>
                  <CV_START_BTN_TEXT>
                    <Trans>Add Liquidity</Trans>
                  </CV_START_BTN_TEXT>
                </CV_START_BTN>
              </CV_ROW>
            </CV_POOL_CONTENT>
            <CV_POOL_IMG src={poolChartsImg} alt="poolChartsImg"></CV_POOL_IMG>
          </CV_POOL_PANEL>
        </CIRCLE_BOTTOM>
        <CV_SWAP_PANEL>
          <CV_SWAP_IMG src={demoFormImg} alt="demoFormImg"></CV_SWAP_IMG>
          <CV_SWAP_CONTENT>
            <CV_SWAP_TITLE_IMG src={swapTitleImg} alt="swapTitleImg"></CV_SWAP_TITLE_IMG>
            <CV_ROW>
              <CV_BLOCKS></CV_BLOCKS>
              <CV_TEXT1 className='leftAlign'><Trans>Swap with <CV_FONT_WHITE>lower</CV_FONT_WHITE> slippage</Trans></CV_TEXT1>
            </CV_ROW>
            <CV_ROW className='minBtn leftAlign'>
              <CV_START_BTN onClick={() => toNextPage('swap')}>
                <CV_START_BTN_TEXT>
                  <Trans>SWAP</Trans>
                </CV_START_BTN_TEXT>
              </CV_START_BTN>
            </CV_ROW>
          </CV_SWAP_CONTENT>
        </CV_SWAP_PANEL>
        <CV_DIAN_LAYOUT>
          <CV_EXTRA_BOX>
            <CV_EXTRA_PANEL>
              <CV_EXTRA_IMG src={extraImg} alt="extraImg"></CV_EXTRA_IMG>
              <CV_EXTRA_TITLE_IMG src={extraTitleImg} alt="extraTitleImg"></CV_EXTRA_TITLE_IMG>
              <CV_ROW className='extra-row'>
                <CV_BLOCKS></CV_BLOCKS>
                <CV_TEXT1 className='leftAlign'><Trans>Growing with the <CV_FONT_YELLOW onClick={() => {
                  window.open('https://www.tabichain.com/')
                }}>Tabi Ecosystem</CV_FONT_YELLOW>, Helping Users Earn  <CV_FONT_WHITE>Extra Rewards</CV_FONT_WHITE></Trans></CV_TEXT1>
                <CV_BLOCKS className='ml18'></CV_BLOCKS>
              </CV_ROW>
            </CV_EXTRA_PANEL>
          </CV_EXTRA_BOX>
          <CV_DIAN></CV_DIAN>
          <CV_HOME_FOOTER_LAYOUT>
              <CV_HOME_FOOTER>
                <CV_LOGO src={logoImg} className="footerLogo" alt="logo" />
                <CV_ICONS>
                  <CV_ICON_IMG src={icon1Img} alt="icon1Img" onClick={() => {
                    window.open('https://x.com/NinjaX_Finance')
                  }}></CV_ICON_IMG>
                  <CV_ICON_IMG src={icon2Img} alt="icon2Img" onClick={() => {
                    window.open('https://discord.com/invite/ninjax')
                  }}></CV_ICON_IMG>
                </CV_ICONS>
                <CV_TEXT3><Trans>2024-All Rights Lecensed</Trans></CV_TEXT3>
              </CV_HOME_FOOTER>
          </CV_HOME_FOOTER_LAYOUT>
        </CV_DIAN_LAYOUT>
      </CV_HOME_BODY>
  </CV_HOME_PAGE>
}
