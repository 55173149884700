import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import { Text } from 'rebass'
import { css, keyframes } from 'styled-components'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import styled from 'styled-components'
import connectBg from '../../assets/images/home/btn-connect.png'
import btnDefaultBg from '../../assets/images/home/btn-default.png'
import marqueeBg from '../../assets/images/home/marquee.png'
import dianBg from '../../assets/images/home/bg-dian.png'

const textFont = css`
  font-family: Nokora;
  font-weight: 400;
  text-align: center;
`
const gradientText = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  background-clip: text;
`

export const CV_HOME_PAGE = styled.div`
  background-color: #232027;
  width: 100%;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
`
export const CV_HEADER_LAYOUT = styled.div`
  position: fixed;
  width: 100%;
  height: 52px;
  line-height: 52px;
  z-index: 200;
  background-color: #232027;
`
export const CV_HOME_HEADER = styled.div`
  ${flexRowNoWrap};
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
`
export const CV_LOGO = styled.img`
  width: 80px;
  height: auto;
  margin-left: 80px;
  &.footerLogo {
    margin-left: 135px;
  }
`
export const CV_MENU = styled.div`
  ${flexRowNoWrap};
  margin-left: 68px;
  gap: 58px;
`
export const CV_MENU_LI = styled.div`
  ${textFont};
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
  &:hover,&:active,&.active {
    color: #FFBF6E;
    font-weight: bold;
  }
`
export const CV_BTN_TYPE1 = styled.div`
  margin-left: auto;
  margin-right: 85px;
  width: 142px;
  height: 37px;
  line-height: 37px;
  background-image: url(${connectBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  ${textFont};
  font-size: 15px;
  color: #F5F5F5;
  cursor: pointer;
`

export const CV_HOME_BODY = styled.div`
`
export const CIRCLE_TOP = styled.div`
  position: relative;
  margin-bottom: 43px;
  min-height: 586px;
`
export const CIRCLE_BG_LEVEL = styled.img`
  width: 948px;
  margin: 0 auto;
  margin-top: -52px;
  display: block;
  animation: bounce 5s infinite;
  @keyframes bounce{
    50%{
      transform: translateY(20px);
    }
  }
`
export const CIRCLE_CONTENT_LEVEL = styled.div`
  position: absolute;
  top: 104px;
  left: 0;
  width: 100%;
  height: 586px;
  z-index: 2;
`
export const CV_ROW = styled.div`
  ${textFont};
  font-size: 26px;
  color: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    margin-bottom: 12px;
  }
  &:last-child {
    margin-top: 59px;
  }
  &.extra-row {
    margin-top: 20px;
  }
  &.leftAlign {
    justify-content: flex-start;
  }
  &.minBtn {
    margin-left: 28px;
    width: 162px;
    height: 52px;
    line-height: 52px;
  }
`
export const CV_TITLE_IMG = styled.img`
  width: 780px;
  margin: 140px auto 0 auto;
  display: block;
`
export const CV_BLOCKS = styled.div`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #D9D9D9;
  &.ml18 {
    margin-left: 18px;
  }
`
export const CV_TEXT1 = styled.p`
  margin: 0 18px;
  color: rgba(255,255,255, 0.5);
  &.leftAlign {
    text-align: left;
    margin: 0 0 0 18px;
  }
`
export const CV_FONT_WHITE = styled.span`
  color: #ffffff;
`
export const CV_START_BTN = styled.div`
  width: 202px;
  height: 62px;
  line-height: 62px;
  background-image: url(${btnDefaultBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`
export const CV_START_BTN_TEXT = styled.div`
  background: linear-gradient(to right, #FFDD85, #FF9A38);
  ${gradientText};
  ${textFont};
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`
export const CIRCLE_BOTTOM = styled.div`
  position: relative;
  min-height: 685px;
`
export const CIRCLE_BTN_BG_LEVEL = styled.img`
  width: 748px;
  margin: 0 auto;
  display: block;
`

export const CIRCLE_BTN_CONTENT_LEVEL = styled.div`
  width: 100%;
  // max-width: 1440px;
  height: 203px;
  margin:0 auto;
  background-image: url(${marqueeBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 182px;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CV_COLUMN = styled.div`
`
export const CV_SON_TITLE = styled.div`
  background: linear-gradient(to right, #FF9D3B, #FFDE85);
  ${gradientText};
  ${textFont};
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 13px;
`
export const CV_TEXT2 = styled.div`
  ${textFont};
  font-size: 38px;
  font-weight: 400;
  color: #FFFFFF;
`
export const CV_IMG_LINE = styled.img`
  margin: 0 79px 0 71px;
  display: block;
  height: 40px;
  width: auto;
`
export const CV_POOL_PANEL = styled.div`
  ${flexRowNoWrap};
  max-width: 1200px;
  margin: -120px auto 0 auto;
  justify-content: space-between;
  &.noshow {
    margin: -400px auto 0 auto;
  }
`
export const CV_POOL_CONTENT = styled.div`
  padding-left: 197px;
`
export const CV_POOL_TITLE_IMG = styled.img`
  width: 342px;
  margin-bottom: 40px;
  margin-top: 19px;
  position: relative;
`
export const CV_POOL_IMG = styled.img`
  // width: 426px;
  width: 538.5px;
  margin-left: 56px;
`
export const CV_SWAP_PANEL = styled.div`
  ${flexRowNoWrap};
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  // justify-content: space-between;
  align-items: center;
`
export const CV_SWAP_CONTENT = styled.div`
`
export const CV_SWAP_IMG = styled.img`
  width: 645px;
  margin-right: 81px;
`
export const CV_SWAP_TITLE_IMG = styled.img`
  width: 221px;
  margin-left: 28px;
  margin-bottom: 40px;
`
export const CV_EXTRA_PANEL = styled.div`
  ${flexColumnNoWrap};
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 210px;
`
export const CV_EXTRA_IMG = styled.img`
  width: 551px;
  margin-bottom: 27px;
  transform: rotate(-8deg);
`
export const CV_EXTRA_TITLE_IMG = styled.img`
  width: 466px;
  margin-bottom: 47px;
  margin-top: 40px;
`
export const CV_FONT_YELLOW = styled.span`
  color: #FFB121;
  cursor: pointer;
`
export const CV_DIAN_LAYOUT = styled.div`
  position: relative;
  margin-top: 330px;
`
export const CV_EXTRA_BOX = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 84px;
`
export const CV_DIAN = styled.div`
  width: 852px;
  height: 546px;
  background-image: url(${dianBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
`
export const CV_HOME_FOOTER_LAYOUT = styled.div`
  width: 100%;
  height: 84px;
  line-height: 84px;
  background-color: #27242A;
  position: absolute;
  bottom: 0;
`
export const CV_HOME_FOOTER = styled.div`
  ${flexRowNoWrap};
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
`
export const CV_ICONS = styled.div`
  ${flexRowNoWrap};
  margin-right: 45px;
  margin-left: auto;
  gap: 22px;
`
export const CV_ICON_IMG = styled.img`
  width: 38px;
  height: 38px;
  display: block;
  cursor: pointer;
`
export const CV_TEXT3 = styled.div`
  ${textFont};
  font-size: 16px;
  font-weight: 400;
  color: rgba(255,255,255,0.5);
  margin-right: 160px;
`
