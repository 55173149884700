export type ChainFormatters = {
  /** Modifies how the Block structure is formatted & typed. */
  block?: ChainFormatter<'block'> | undefined
  /** Modifies how the Transaction structure is formatted & typed. */
  transaction?: ChainFormatter<'transaction'> | undefined
  /** Modifies how the TransactionReceipt structure is formatted & typed. */
  transactionReceipt?: ChainFormatter<'transactionReceipt'> | undefined
  /** Modifies how the TransactionRequest structure is formatted & typed. */
  transactionRequest?: ChainFormatter<'transactionRequest'> | undefined
}

export type Chain<
  formatters extends ChainFormatters | undefined = ChainFormatters | undefined,
  custom extends Record<string, unknown> | undefined =
  | Record<string, unknown>
  | undefined,
> = {
  /** Collection of block explorers */
  blockExplorers?:
  | {
    [key: string]: ChainBlockExplorer
    default: ChainBlockExplorer
  }
  | undefined
  /** Collection of contracts */
  contracts?:
  | Prettify<
    {
      [key: string]:
      | ChainContract
      | { [sourceId: number]: ChainContract | undefined }
      | undefined
    } & {
      ensRegistry?: ChainContract | undefined
      ensUniversalResolver?: ChainContract | undefined
      multicall3?: ChainContract | undefined
    }
  >
  | undefined
  /** ID in number form */
  id: number
  /** Human-readable name */
  name: string
  /** Currency used by chain */
  nativeCurrency: ChainNativeCurrency
  /** Collection of RPC endpoints */
  rpcUrls: {
    [key: string]: ChainRpcUrls
    default: ChainRpcUrls
  }
  /** Source Chain ID (ie. the L1 chain) */
  sourceId?: number | undefined
  /** Flag for test networks */
  testnet?: boolean | undefined

  /** Custom chain data. */
  custom?: custom | undefined
  /**
   * Modifies how chain data structures (ie. Blocks, Transactions, etc)
   * are formatted & typed.
   */
  formatters?: formatters | undefined
  /** Modifies how data (ie. Transactions) is serialized. */
  serializers?: ChainSerializers<formatters> | undefined
  /** Modifies how fees are derived. */
  fees?: ChainFees<formatters | undefined> | undefined
}

/**
* @description Combines members of an intersection into a readable type.
*
* @see {@link https://twitter.com/mattpocockuk/status/1622730173446557697?s=20&t=NdpAcmEFXY01xkqU3KO0Mg}
* @example
* Prettify<{ a: string } & { b: string } & { c: number, d: bigint }>
* => { a: string, b: string, c: number, d: bigint }
*/
export type Prettify<T> = {
  [K in keyof T]: T[K]
} & {}

/**
 * @description Assigns the properties of U onto T.
 *
 * @example
 * Assign<{ a: string, b: number }, { a: undefined, c: boolean }>
 * => { a: undefined, b: number, c: boolean }
 */
export type Assign<T, U> = Assign_<T, U> & U
type Assign_<T, U> = {
  [K in keyof T as K extends keyof U
  ? U[K] extends void
  ? never
  : K
  : K]: K extends keyof U ? U[K] : T[K]
}

export function defineChain<
  formatters extends ChainFormatters,
  const chain extends Chain<formatters>,
> (chain: chain): Prettify<Assign<Chain<undefined>, chain>> {
  return {
    formatters: undefined,
    fees: undefined,
    serializers: undefined,
    ...chain,
  } as Assign<Chain<undefined>, chain>
}
