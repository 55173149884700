import { ChainId } from '@taibiex/sdk-core'
import { getChainInfo, isSupportedChainId } from 'constants/chains'
// import { isSameAddress } from 'utilities/src/addresses'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
// import AvaxLogo from '../../assets/svg/avax_logo.svg'
// import BnbLogo from '../../assets/svg/bnb-logo.svg'
// import CeloLogo from '../../assets/svg/celo_logo.svg'
// import MaticLogo from '../../assets/svg/matic-token-icon.svg'
// import { PORTAL_ETH_CELO, isCelo, nativeOnChain } from '../../constants/tokens'

export function getNativeLogoURI(chainId: ChainId = ChainId.MAINNET): string {
  return EthereumLogo
  // switch (chainId) {
  //   case ChainId.POLYGON:
  //   case ChainId.POLYGON_MUMBAI:
  //     return MaticLogo
  //   case ChainId.BNB:
  //     return BnbLogo
  //   case ChainId.CELO:
  //   case ChainId.CELO_ALFAJORES:
  //     return CeloLogo
  //   case ChainId.AVALANCHE:
  //     return AvaxLogo
  //   default:
  //     return EthereumLogo
  // }
}

export function getTokenLogoURI(address: string, chainId: ChainId = ChainId.MAINNET): string | void {
  const networkName = isSupportedChainId(chainId) ? getChainInfo({ chainId }).assetRepoNetworkName : undefined

  // if (isCelo(chainId) && isSameAddress(address, nativeOnChain(chainId).wrapped.address)) {
  //   return CeloLogo
  // }
  // if (isCelo(chainId) && isSameAddress(address, PORTAL_ETH_CELO.address)) {
  //   return EthereumLogo
  // }

  if (networkName) {
    return `https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/${address}/logo.png`
    // return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }
}
