import { css, keyframes } from 'styled-components'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'
import styled from 'styled-components'
import TwitterIcon from './assets/twitter.svg'
import TwitterHoverIcon from './assets/twitter_h.svg'
import TelegramIcon from './assets/telegram.svg'
import TelegramHoverIcon from './assets/telegram_h.svg'
import DiscordIcon from './assets/discord.svg'
import DiscordHoverIcon from './assets/discord_h.svg'
import poolSvg from './assets/pool.svg'
import swapSvg from './assets/swpa.svg'

const widthLimit = css`
  max-width: 1800px;
  margin: 0 60px;
`
const test16Bold = css`
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  color: #2C2C2C;
`
const text24Normal = css`
  font-family:'KronaOne';
  font-size: 24px;
  font-weight: normal;
`
// const animateOne = css`
//   animation: changeY 5s infinite;
//   @keyframes changeY{
//     50%{
//       transform: translateY(20px);
//     }
//   }
// `
const animateHover = css`
  animation: changeY 0.5s 1;
  @keyframes changeY{
    50%{
      transform: translateY(20px);
    }
  }
`

export const CV_HOME_PAGE = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CV_LAYOUT_BOX = styled.div `
  ${widthLimit};
  height: auto;
  width: -webkit-fill-available;
  &.header {
    ${flexRowNoWrap};
    justify-content: space-between;
    align-items: center;
    padding: 24px 0 0 0;
  }
  &.relative {
    position: relative;
    $.spline-canvas {
      border: 2px solid green;
    }
  }
  &.flex-center {
    ${flexRowNoWrap};
    justify-content: space-evenly;
    align-items: center;
  }
  &.footer {
    ${flexRowNoWrap};
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
  }
`
export const CV_LOGO = styled.img`
  width: 157px;
  height: 30px;
  cursor: pointer;
`
export const CV_MENU_UL = styled.ul`
  ${flexRowNoWrap};
  gap: 20px;
  border-radius: 4px;
  padding: 8px 20px;
  box-shadow: 0 10px 16px rgba(0,0,0,0.1);
`
export const CV_MENU_LI = styled.li`
  list-style-type: none;
  width: 132px;
  height: 36px;
  border-radius: 4px;
  line-height: 36px;
  ${test16Bold};
  text-align: center;
  cursor: pointer;
  &.active, &:hover {
    background: #2C2C2C;
    color: #FFFFFF;
  }
`

export const CV_BUTTON = styled.button`
  padding: 16px 24px;
  border: unset;
  border-radius: 4px;
  ${test16Bold};
  cursor: pointer;
  background: #2C2C2C;
  color: #FFFFFF;
  &.isNull {
    background: #FFFFFF;
    cursor: default;
  }
`

export const CV_P1 = styled.p`
  padding: 123px 0 0 0;
  margin: 0 0;
  text-align: center;
  ${text24Normal};
  color: #7E7E7E;
`
export const CV_IFRAME = styled.iframe`
  width: 100%;
  height: 497px;
  border: unset;
`
export const CV_MASK = styled.div`
  position: absolute;
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
`

export const CV_BUTTON_ORANGE = styled.button`
  ${text24Normal};
  padding: 8px 16px;
  color: #FFBC5D;
  border: 1px solid #FFBC5D;
  margin: 0 auto;
  display: block;
  background: #FFFFFF;
  cursor: pointer;
  &:hover {
    color: #2C2C2C;
    border: 1px solid #2C2C2C;
  }
`
export const CV_ITEM_BOX = styled.div`
  ${flexRowNoWrap};
  width: 784px;
  height: 130px;
  margin: 64px auto 0 auto;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
`
export const CV_ITEM = styled.div`
  width: 50%;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 20px;
`
export const CV_TITLE = styled.h1`
  margin: 0 0 24px 0;
  font-family: 'KronaOne';
  font-size: 16px;
  background: linear-gradient(to right, #FF9D3B, #FFDE85);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`
export const CV_VALUE = styled.div`
  ${text24Normal};
  color: #000000;
`
export const CV_LINE1 = styled.span`
  display: block;
  height: 40px;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
`
export const CV_CONTENT = styled.div`
  ${flexRowNoWrap};
  gap: 70px;
  width: 100%;
  max-width: 1432px;
  margin: 0 auto;
`
export const CV_CONTENT_ITEM = styled.div`
  max-width: 680px;
  width: 50%;
  border-radius: 20px;
  ${flexColumnNoWrap};
  justify-content: center;
  align-items: flex-start;
  box-shadow: 2px 30px 100px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 80px 60px 60px 60px;
  background-repeat: no-repeat;
  margin-top: 220px;
  &.pool {
    background-image: url(${poolSvg});
    background-position: 125px 35px;
    &:hover {
      ${animateHover};
    }
  }
  &.swap {
    background-image: url(${swapSvg});
    background-position: 164px 35px;
    &:hover {
      ${animateHover};
    }
  }
`
export const CV_H_TITLE = styled.h1`
  margin: 0 0 30px 0;
  padding: 0 0;
  font-family: 'KronaOne';
  font-size: 60px;
  color: #000000;
`
export const CV_P_CTX = styled.p`
  margin: 0 0 60px 0;
  padding: 0 0;
  font-family: 'KronaOne';
  font-size: 24px;
  color: rgba(0,0,0,0.5);
  min-height: 80px;
`
export const CV_H_BUTTON = styled.button`
  ${text24Normal};
  color: #FFBC5D;
  background: #FFFFFF;
  padding: 8px 16px;
  border: 1px solid #FFBC5D;
  cursor: pointer;
  &:hover {
    color: #2C2C2C;
    border: 1px solid #2C2C2C;
  }
`
export const CV_H_I = styled.i`
  color: #000000;
  font-style: normal;
`

export const CV_EXTRA = styled.div`
  ${flexColumnNoWrap};
  justify-content: center;
  align-items: center;
  gap: 30px;
  max-width: 1432px;
  height: 265px;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 2px 30px 100px rgba(0, 0, 0, 0.2);
  margin: 72px auto 118px auto;
  &:hover {
    ${animateHover};
  }
`
export const CV_EXTRA_TITLE = styled.h1`
  padding: 0 0;
  margin: 0 0;
  font-size: 60px;
  font-family: 'KronaOne';
`
export const CV_EXTRA_P = styled.p`
  padding: 0 0;
  margin: 0 0;
  font-size: 24px;
  line-height: 40px;
  font-family: 'KronaOne';
  color: rgba(0,0,0,0.5);
`
export const CV_I_YELLOW = styled.i`
  background: linear-gradient(to right, #FF9D3B, #FFDE85);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-style: normal;
`
export const CV_I_BLACK = styled.i`
  color: #000000;
  font-style: normal;
`
export const CV_TEXT_COPYRIGHT = styled.span`
  font-family: 'KronaOne';
  font-size: 12px;
  color: #7E7E7E;
`
export const CV_ICON_UL = styled.ul`
  ${flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`
export const CV_ICON_LI = styled.li`
  list-style-type: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &.twitter {
    background-image: url(${TwitterIcon});
    &.active, &:hover {
      background-image: url(${TwitterHoverIcon});
    }
  }
  &.discord {
    background-image: url(${DiscordIcon});
    &.active, &:hover {
      background-image: url(${DiscordHoverIcon});
    }
  }
  &.telegram {
    background-image: url(${TelegramIcon});
    &.active, &:hover {
      background-image: url(${TelegramHoverIcon});
    }
  }
`
