import { Currency } from '@taibiex/sdk-core'
import { CurrencyListRow, CurrencyListSectionTitle } from 'components/SearchModal/CurrencyList'
import { CurrencySearchFilters } from 'components/SearchModal/CurrencySearch'
import { chainIdToBackendChain, useSupportedChainId } from 'constants/chains'
import { gqlTokenToCurrencyInfo } from 'graphql/data/types'
import { useFallbackListTokens, useToken } from 'hooks/Tokens'
import { useTokenBalances } from 'hooks/useTokenBalances'
import { t } from 'i18next'
import { getTokenFilter } from 'lib/hooks/useTokenList/filtering'
import { getSortedPortfolioTokens } from 'lib/hooks/useTokenList/sorting'
import { useMemo } from 'react'
import { useUserAddedTokens } from 'state/user/userAddedTokens'
import { UserAddedToken } from 'types/tokens'
import {
  Chain,
  Token as GqlToken,
  TokenSortableField,
  useSearchTokensWebQuery,
  useTopTokensQuery,
} from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { isSameAddress } from 'utilities/src/addresses'
import { useChainId } from 'wagmi'

interface CurrencySearchParams {
  searchQuery?: string
  filters?: CurrencySearchFilters
  selectedCurrency?: Currency | null
  otherSelectedCurrency?: Currency | null
}

interface CurrencySearchResults {
  searchCurrency?: Currency | null
  allCurrencyRows: CurrencyListRow[]
  loading: boolean
}

const currencyListRowMapper = (currency: Currency) => new CurrencyListRow(currency)
const searchResultsCurrencyListMapper = (currency: Currency) => new CurrencyListRow(currency, { showAddress: true })
const gqlCurrencyMapper = (gqlToken: any) => {
  const currencyInfo = gqlTokenToCurrencyInfo(gqlToken as GqlToken)
  return currencyInfo ? currencyInfo.currency : undefined
}

function isEmpty(query: string | undefined): query is undefined {
  return !query || query.length === 0
}

// const tokenLists = [
//   {
//     "id": "VG9rZW46RVRIRVJFVU1fMHhjMDJhYWEzOWIyMjNmZThkMGEwZTVjNGYyN2VhZDkwODNjNzU2Y2My",
//     "address": "0xCde9c6ad3f82f322AC86DC63eFF63bC405072F95",
//     "chain": "ETHEREUM",
//     "symbol": "WTABI",
//     "name": "Wrapped TABI",
//     "decimals": 18,
//     "standard": "ERC20",
//     "project": {
//       "id": "VG9rZW5Qcm9qZWN0OkVUSEVSRVVNXzB4YzAyYWFhMzliMjIzZmU4ZDBhMGU1YzRmMjdlYWQ5MDgzYzc1NmNjMl9XRVRI",
//       "name": "WTABI",
//       "logo": {
//         "id": "SW1hZ2U6aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL1VuaXN3YXAvYXNzZXRzL21hc3Rlci9ibG9ja2NoYWlucy9ldGhlcmV1bS9hc3NldHMvMHhDMDJhYUEzOWIyMjNGRThEMEEwZTVDNEYyN2VBRDkwODNDNzU2Q2MyL2xvZ28ucG5n",
//         "url": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0xCde9c6ad3f82f322AC86DC63eFF63bC405072F95/logo.png",
//         "__typename": "Image"
//       },
//       "safetyLevel": "VERIFIED",
//       "logoUrl": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0xCde9c6ad3f82f322AC86DC63eFF63bC405072F95/logo.png",
//       "isSpam": false,
//       "__typename": "TokenProject"
//     },
//     "__typename": "Token"
//   },
//   {
//     "id": "VG9rZW46RVRIRVJFVU1fMHhhMGI4Njk5MWM2MjE4YjM2YzFkMTlkNGEyZTllYjBjZTM2MDZlYjQ4",
//     "address": "0xcDc10593a66185AAa206665C5083ac51Ad935F91",
//     "chain": "ETHEREUM",
//     "symbol": "USDC",
//     "name": "USD Coin",
//     "decimals": 18,
//     "standard": "ERC20",
//     "project": {
//       "id": "VG9rZW5Qcm9qZWN0OkVUSEVSRVVNXzB4YTBiODY5OTFjNjIxOGIzNmMxZDE5ZDRhMmU5ZWIwY2UzNjA2ZWI0OF9VU0RD",
//       "name": "USDC",
//       "logo": {
//         "id": "SW1hZ2U6aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL1VuaXN3YXAvYXNzZXRzL21hc3Rlci9ibG9ja2NoYWlucy9ldGhlcmV1bS9hc3NldHMvMHhBMGI4Njk5MWM2MjE4YjM2YzFkMTlENGEyZTlFYjBjRTM2MDZlQjQ4L2xvZ28ucG5n",
//         "url": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0xcDc10593a66185AAa206665C5083ac51Ad935F91/logo.png",
//         "__typename": "Image"
//       },
//       "safetyLevel": "VERIFIED",
//       "logoUrl": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0xcDc10593a66185AAa206665C5083ac51Ad935F91/logo.png",
//       "isSpam": false,
//       "__typename": "TokenProject"
//     },
//     "__typename": "Token"
//   },
//   {
//     "id": "VG9rZW46RVRIRVJFVU1fMHhkYWMxN2Y5NThkMmVlNTIzYTIyMDYyMDY5OTQ1OTdjMTNkODMxZWM3",
//     "address": "0x2CcaD515c13Df2178f6960304aE0Dbe0428E8d28",
//     "chain": "ETHEREUM",
//     "symbol": "USDT",
//     "name": "Tether USD",
//     "decimals": 18,
//     "standard": "ERC20",
//     "project": {
//       "id": "VG9rZW5Qcm9qZWN0OkVUSEVSRVVNXzB4ZGFjMTdmOTU4ZDJlZTUyM2EyMjA2MjA2OTk0NTk3YzEzZDgzMWVjN19UZXRoZXI=",
//       "name": "Tether",
//       "logo": {
//         "id": "SW1hZ2U6aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL1VuaXN3YXAvYXNzZXRzL21hc3Rlci9ibG9ja2NoYWlucy9ldGhlcmV1bS9hc3NldHMvMHhkQUMxN0Y5NThEMmVlNTIzYTIyMDYyMDY5OTQ1OTdDMTNEODMxZWM3L2xvZ28ucG5n",
//         "url": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0x2CcaD515c13Df2178f6960304aE0Dbe0428E8d28/logo.png",
//         "__typename": "Image"
//       },
//       "safetyLevel": "VERIFIED",
//       "logoUrl": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0x2CcaD515c13Df2178f6960304aE0Dbe0428E8d28/logo.png",
//       "isSpam": false,
//       "__typename": "TokenProject"
//     },
//     "__typename": "Token"
//   },
//   // {
//   //   "id": "4",
//   //   "address": "0x74A380E99F7095597A70348F68B4eF5Dca518e77",
//   //   "chain": "ETHEREUM",
//   //   "symbol": "META",
//   //   "name": "Meta",
//   //   "decimals": 18,
//   //   "standard": "ERC20",
//   //   "project": {
//   //     "id": "VG9rZW5Qcm9qZWN0OkVUSEVSRVVNXzB4ZGFjMTdmOTU4ZDJlZTUyM2EyMjA2MjA2OTk0NTk3YzEzZDgzMWVjN19UZXRoZXI=",
//   //     "name": "Tether",
//   //     "logo": {
//   //       "id": "SW1hZ2U6aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL1VuaXN3YXAvYXNzZXRzL21hc3Rlci9ibG9ja2NoYWlucy9ldGhlcmV1bS9hc3NldHMvMHhkQUMxN0Y5NThEMmVlNTIzYTIyMDYyMDY5OTQ1OTdDMTNEODMxZWM3L2xvZ28ucG5n",
//   //       "url": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0x74A380E99F7095597A70348F68B4eF5Dca518e77/logo.png",
//   //       "__typename": "Image"
//   //     },
//   //     "safetyLevel": "VERIFIED",
//   //     "logoUrl": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0x74A380E99F7095597A70348F68B4eF5Dca518e77/logo.png",
//   //     "isSpam": false,
//   //     "__typename": "TokenProject"
//   //   },
//   //   "__typename": "Token"
//   // },
//   {
//     "id": "5",
//     "address": "0x61B0C158ee2DDF6f430325f56dDffFbEe1b88B00",
//     "chain": "ETHEREUM",
//     "symbol": "JAX",
//     "name": "Jax",
//     "decimals": 18,
//     "standard": "ERC20",
//     "project": {
//       "id": "5-1",
//       "name": "JAX",
//       "logo": {
//         "id": "5-1-logo",
//         "url": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0x61B0C158ee2DDF6f430325f56dDffFbEe1b88B00/logo.png",
//         "__typename": "Image"
//       },
//       "safetyLevel": "VERIFIED",
//       "logoUrl": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0x61B0C158ee2DDF6f430325f56dDffFbEe1b88B00/logo.png",
//       "isSpam": false,
//       "__typename": "TokenProject"
//     },
//     "__typename": "Token"
//   },
//   // {
//   //   "id": "6",
//   //   "address": "0xFaD5344F00b6ee0d3598dCf14c63682357032369",
//   //   "chain": "ETHEREUM",
//   //   "symbol": "USDX",
//   //   "name": "Usdx",
//   //   "decimals": 18,
//   //   "standard": "ERC20",
//   //   "project": {
//   //     "id": "6-1",
//   //     "name": "JAX",
//   //     "logo": {
//   //       "id": "6-1-logo",
//   //       "url": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0xFaD5344F00b6ee0d3598dCf14c63682357032369/logo.png",
//   //       "__typename": "Image"
//   //     },
//   //     "safetyLevel": "VERIFIED",
//   //     "logoUrl": "https://raw.githubusercontent.com/metabitLab/assets/main/blockchains/tabi/assets/0xFaD5344F00b6ee0d3598dCf14c63682357032369/logo.png",
//   //     "isSpam": false,
//   //     "__typename": "TokenProject"
//   //   },
//   //   "__typename": "Token"
//   // },
// ]

// const toLocaleLowerCase = (str: string) => str.toLocaleLowerCase()

export function useCurrencySearchResults({
  searchQuery,
  filters,
  selectedCurrency,
  otherSelectedCurrency,
}: CurrencySearchParams): CurrencySearchResults {
  const chainId = useChainId()
  const supportedChain = useSupportedChainId(chainId)

  /**
   * GraphQL queries for tokens and search results
   */
  const { data: searchResults, loading: searchResultsLoading } = useSearchTokensWebQuery({
    variables: {
      searchQuery: searchQuery ?? '',
      chains: [chainIdToBackendChain({ chainId: supportedChain, withFallback: true }) ?? Chain.Ethereum],
    },
    skip: !searchQuery,
  })
  // const _searchQuery = searchQuery ?? ''

  // const searchTokens = tokenLists.filter(x => toLocaleLowerCase(x.name).includes(toLocaleLowerCase(_searchQuery)) || toLocaleLowerCase(x.address).includes(toLocaleLowerCase(_searchQuery)) || toLocaleLowerCase(x.symbol).includes(toLocaleLowerCase(_searchQuery)))
  // const searchResults = { searchTokens }

  // const searchResultsLoading = false

  // TODO: Select Token List From Graphl
  const { data: popularTokens, loading: popularTokensLoading } = useTopTokensQuery({
    fetchPolicy: 'cache-first',
    variables: {
      chain: chainIdToBackendChain({ chainId: supportedChain, withFallback: true }) ?? Chain.Ethereum,
      orderBy: TokenSortableField.Popularity,
      page: 1,
      pageSize: 100,
    },
  })
  // const popularTokensLoading = false
  // const popularTokens = {
  //   topTokens: tokenLists
  // }

  const sortedPopularTokens = useMemo(() => {
    if (!popularTokens?.topTokens) {
      return undefined
    }
    return [...popularTokens.topTokens].sort((a, b) => {
      if (a?.project?.name && b?.project?.name) {
        return a.project.name.localeCompare(b.project.name)
      }
      return 0
    })
  }, [popularTokens?.topTokens])
  const { balanceMap, balanceList, loading: balancesLoading } = useTokenBalances()

  /**
   * Token-list based results.
   */

  // Queries for a single token directly by address, if the query is an address.
  const searchToken = useToken(searchQuery)
  const defaultAndUserAddedTokens = useFallbackListTokens(chainId)
  const userAddedTokens = useUserAddedTokens()

  const gqlSearchResultsEmpty =
    (!searchResults?.searchTokens || searchResults.searchTokens.length === 0) && !searchResultsLoading
  const gqlPopularTokensEmpty =
    (!popularTokens?.topTokens || popularTokens.topTokens.length === 0) && !popularTokensLoading

  /**
   * Results processing: sorting, filtering, and merging data sources into the final list.
   */
  const { sortedCombinedTokens, portfolioTokens, sortedTokensWithoutPortfolio } = useMemo(() => {
    const fullBaseList = (() => {
      if ((!isEmpty(searchQuery) && gqlSearchResultsEmpty) || (isEmpty(searchQuery) && gqlPopularTokensEmpty)) {
        return Object.values(defaultAndUserAddedTokens)
      } else if (!isEmpty(searchQuery)) {
        return [
          ...((searchResults?.searchTokens?.map(gqlCurrencyMapper).filter(Boolean) as Currency[]) ?? []),
          ...userAddedTokens
            .filter(getTokenFilter(searchQuery))
            .filter(
              (userAddedToken) =>
                !searchResults?.searchTokens?.find((token) => isSameAddress(token?.address, userAddedToken.address))
            ),
        ]
      } else {
        return [
          ...((sortedPopularTokens?.map(gqlCurrencyMapper).filter(Boolean) as Currency[]) ?? []),
          ...userAddedTokens,
        ]
      }
    })()

    // If we're using gql token lists and there's a search query, we don't need to
    // filter because the backend already does it for us.
    if (!isEmpty(searchQuery) && !gqlSearchResultsEmpty) {
      return {
        sortedCombinedTokens: fullBaseList,
        portfolioTokens: [],
        sortedTokensWithoutPortfolio: fullBaseList,
      }
    }

    // Filter out tokens with balances so they aren't duplicated when we merge below.
    const filteredListTokens = fullBaseList.filter((token) => {
      if (token.isNative) {
        return !((token.symbol ?? 'ETH') in balanceMap)
      } else {
        return !(token.address?.toLowerCase() in balanceMap)
      }
    })

    if (balancesLoading) {
      return {
        sortedCombinedTokens: filteredListTokens,
        portfolioTokens: [],
        sortedTokensWithoutPortfolio: filteredListTokens,
      }
    }

    const portfolioTokens = getSortedPortfolioTokens(balanceList, balanceMap, chainId, {
      hideSmallBalances: false,
      hideSpam: true,
    })
    const mergedTokens = [...(portfolioTokens ?? []), ...filteredListTokens]

    // This is where we apply extra filtering based on the callsite's
    // customization, on top of the basic searchQuery filtering.
    const currencyFilter = (currency: Currency) => {
      if (filters?.onlyShowCurrenciesWithBalance) {
        if (currency.isNative) {
          return balanceMap[currency.symbol ?? 'ETH']?.usdValue > 0
        }

        return balanceMap[currency.address?.toLowerCase()]?.usdValue > 0
      }

      if (currency.isNative && filters?.disableNonToken) {
        return false
      }

      // If there is no query, filter out unselected user-added tokens with no balance.
      if (isEmpty(searchQuery) && currency instanceof UserAddedToken) {
        if (selectedCurrency?.equals(currency) || otherSelectedCurrency?.equals(currency)) return true
        return balanceMap[currency.address.toLowerCase()]?.usdValue > 0
      }

      return true
    }

    const sortedCombinedTokens =
      !isEmpty(searchQuery) && gqlSearchResultsEmpty ? mergedTokens.filter(getTokenFilter(searchQuery)) : mergedTokens

    return {
      sortedCombinedTokens: sortedCombinedTokens.filter(currencyFilter),
      sortedTokensWithoutPortfolio: filteredListTokens.filter(currencyFilter),
      portfolioTokens: portfolioTokens.filter(currencyFilter),
    }
  }, [
    searchQuery,
    gqlSearchResultsEmpty,
    balancesLoading,
    balanceList,
    balanceMap,
    chainId,
    gqlPopularTokensEmpty,
    defaultAndUserAddedTokens,
    searchResults?.searchTokens,
    userAddedTokens,
    sortedPopularTokens,
    filters?.onlyShowCurrenciesWithBalance,
    filters?.disableNonToken,
    selectedCurrency,
    otherSelectedCurrency,
  ])

  const finalCurrencyList: CurrencyListRow[] = useMemo(() => {
    if (!isEmpty(searchQuery) || portfolioTokens.length === 0) {
      return [
        new CurrencyListSectionTitle(searchQuery ? t`Search results` : t`Popular tokens`),
        ...sortedCombinedTokens.map(searchQuery ? searchResultsCurrencyListMapper : currencyListRowMapper),
      ]
    } else if (sortedTokensWithoutPortfolio.length === 0) {
      return [new CurrencyListSectionTitle(t`Your tokens`), ...portfolioTokens.map(currencyListRowMapper)]
    } else {
      return [
        new CurrencyListSectionTitle(t`Your tokens`),
        ...portfolioTokens.map(currencyListRowMapper),
        new CurrencyListSectionTitle(t`Popular tokens`),
        ...sortedTokensWithoutPortfolio.map(currencyListRowMapper),
      ]
    }
  }, [searchQuery, portfolioTokens, sortedTokensWithoutPortfolio, sortedCombinedTokens])

  return {
    loading: searchResultsLoading || popularTokensLoading || balancesLoading,
    searchCurrency: searchToken,
    allCurrencyRows: finalCurrencyList,
  }
}
